import logo from './logo.svg';
import './App.css';
import './components/Navbar'
import name from './assets/string' 
import {Bars3Icon, XIcon} from '@heroicons/react/24/solid'
import Home from './components/Home'
import Navbar from './components/Navbar';
function App() {
  return (
    <div>
<div class="bg-indigo-50">
    <header>
        <nav class="bg-indigo-50 border-gray-200 px-4 lg:px-6 py-2.5">
            <div class="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
                <a href="#" class="flex items-center"><span class="text-indigo-600 self-center text-xl font-semibold whitespace-nowrap">{name}</span></a>
                <div class="flex items-center lg:order-2">
                        <span class="sr-only">Open main menu</span><svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg><svg class="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                </div>
                <div class="hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1" id="mobile-menu-2">
                    <ul class="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
                        <li><a href="#" class="block py-2 pr-4 pl-3 hover:text-blue-800 text-black rounded lg:bg-transparent lg:p-0" aria-current="page">About Us</a></li>
                        <li><a href="#" class="block py-2 pr-4 pl-3 hover:text-blue-800 text-black rounded lg:bg-transparent lg:p-0" aria-current="page">Programs</a></li>
                        <li><a href="#" class="block py-2 pr-4 pl-3 hover:text-blue-800 text-black rounded lg:bg-transparent lg:p-0" aria-current="page">Donate</a></li>
                        <li><a href="#" class="block py-2 pr-4 pl-3 hover:text-blue-800 text-black rounded lg:bg-transparent lg:p-0" aria-current="page">Contact</a></li>
                    </ul>
                </div>
            </div>
        </nav>
    </header>
    <div class="relative isolate px-6 pt-14 lg:px-8">
        <div class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true"></div>
        <div class="mx-auto max-w-2xl py-10 sm:py-48 lg:py-10">
            <div class="text-center">
                <h1 class="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">Empowering Communities Through Technology</h1>
                <p class="mt-6 text-lg leading-8 text-gray-600">Providing computer access and digital skills literacy to underserved communities.</p>
                <div class="mt-10 flex items-center justify-center gap-x-6">
                    <a href="#" class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Sign up for a program</a><a href="#" class="text-sm font-semibold leading-6 text-gray-900">Learn more about our mission<span aria-hidden="true">→</span></a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="bg-white py-24 sm:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-4xl sm:text-center">
            <h2 class="text-base font-semibold leading-7 text-indigo-600">Affordable Pricing Plans</h2>
            <p class="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Choose the plan that suits your needs</p>
        </div>
        <p class="mx-auto mt-6 max-w-2xl text-lg leading-8 text-gray-600 sm:text-center">We offer flexible pricing options to ensure everyone has access to our services.</p>
    </div>
</div>
<div class="bg-indigo-600">
    <div class="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
        <div class="mx-auto max-w-2xl text-center">
            <h2 class="text-3xl font-bold tracking-tight text-white sm:text-4xl"><br />Help bridge the digital divide</h2>
            <p class="mx-auto mt-6 max-w-xl text-lg leading-8 text-indigo-200">Your old computer can make a difference in someone&#x27;s life. Donate it to {name}.org and help us provide computer access and digital skills literacy to those in need.</p>
            <div class="mt-10 flex items-center justify-center gap-x-6">
                <a href="#" class="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">Donate now</a
                ><a href="#" class="text-sm font-semibold leading-6 text-white"
                    >Learn mo
                    <span aria-hidden="true">→</span></a
                >
            </div>
        </div>
    </div>
</div>
<div class="bg-white">
    <div class="mx-auto max-w-7xl px-6 py-24 sm:pt-32 lg:px-8 lg:py-40">
        <div class="lg:grid lg:grid-cols-12 lg:gap-8">
            <div class="lg:col-span-5">
                <h2 class="text-2xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
                <p class="mt-4 text-base leading-7 text-gray-600">
                    Can’t find the answer you’re looking for? Reach out to o
                    <a href="#" class="font-semibold text-indigo-600 hover:text-indigo-500">customer support</a>
                team.
                </p>
            </div>
            <div class="mt-10 lg:col-span-7 lg:mt-0">
                <dl class="space-y-10">
                    <div>
                        <dt class="text-base font-semibold leading-7 text-gray-900">What is {name}.org?</dt>
                        <dd class="mt-2 text-base leading-7 text-gray-600">{name}.org is a nonprofit organization focused on computer access and digital skills literacy.</dd>
                    </div>
                    <div>
                        <dt class="text-base font-semibold leading-7 text-gray-900">How can I donate my old computer?</dt>
                        <dd class="mt-2 text-base leading-7 text-gray-600">To donate your old computer, please visit our website and fill out the donation form.</dd>
                    </div>
                    <div>
                        <dt class="text-base font-semibold leading-7 text-gray-900">What happens to the donated computers?</dt>
                        <dd class="mt-2 text-base leading-7 text-gray-600">The donated computers are refurbished and distributed to individuals and organizations in need.</dd>
                    </div>
                    <div>
                        <dt class="text-base font-semibold leading-7 text-gray-900">Can I get a tax deduction for my computer donation?</dt>
                        <dd class="mt-2 text-base leading-7 text-gray-600">Yes, {name}.org is a registered nonprofit organization, and you can receive a tax deduction for your computer donation.</dd>
                    </div>
                    <div>
                        <dt class="text-base font-semibold leading-7 text-gray-900">How can I apply for a computer from {name}.org?</dt>
                        <dd class="mt-2 text-base leading-7 text-gray-600">To apply for a computer, please visit our website and fill out the application form.</dd>
                    </div>
                </dl>
            </div>
        </div>
    </div>
</div>
</div>
  );
}

export default App;

